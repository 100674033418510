import axios from 'axios';
import router from '@/router';
import { Message } from 'element-ui';
import config from '@/config/defult';

axios.defaults.withCredentials = true;
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8';
// 创建axios实例
const service = axios.create({
    // baseURL: 'https://bqsb.rarb.cn/',
    baseURL: `${config.PROJECT_BASE_URL}/`,
    // baseURL: 'http://10.102.12.68:8060/',
    // 超时
    timeout: 10000
});

// 请求拦截
service.interceptors.request.use((config) => {
    console.log(localStorage.getItem('bqsb_login'));
    // const needToken = (config.headers || {}).needToken === true;
    if (localStorage.getItem('bqsb_login')) {
        config.headers['Authorization'] = localStorage.getItem('bqsb_login'); // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    return config;
});

// 响应拦截器
service.interceptors.response.use((res) => {
    if (res.data.code === 401) {
        Message.warning('登录已过期,请重新登录');
        router.push({ path: '/' });
    }
    return res.data;
});
export default service;
